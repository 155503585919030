<template>
    <div id="remForm">
          <div class="card mb-3">
            <div class="card-body pt-0 pb-0">
  
              <!-- form -->
              <div class="mt-4">
                <h6 class="mb-4 text-center text-primary">Vérification du kilométrage</h6>
  
                <FormulateForm @submit="validationForm">
                    <FormulateInput
                      type="textarea"
                      v-model="infos"
                      label="Informations trouvées"
                    />

                    <FormulateInput
                    v-if="editing === false"
                    type="number"
                    v-model="deplacement.kmDebut"
                    label="Km début"
                    placeholder="Indiquer le nombre de km"
                    validation="number"
                    min="0"
                    step="1"
                    />

                    <FormulateInput
                    v-if="editing === true"
                    type="number"
                    v-model="deplacement.kmFin"
                    label="Km fin"
                    placeholder="Indiquer le nombre de km"
                    validation="number"
                    min="0"
                    step="1"
                    />

                  <div v-if="error" class="alert alert-warning w-100">
                    <i class="fas fa-exclamation-triangle"></i>
                    {{error}}
                  </div>
                  
                  <FormulateInput
                    type="submit"
                    label="Valider"
                    id="buttonDeplacementValidation"
                    input-class="btn btn-success w-100 text-white mt-3"
                  />
                  
                </FormulateForm>
  
              </div>
  
            </div>
          </div>
  
    </div>
  </template>
  
  <script>
  import router from '../router'
  import UserService from '../services/user.service'
  
  var temp = Object.freeze({ 
    kmDebut: null,
    kmFin: null,
    infos: null,
  });
  
  export default {
    name: 'Form',
    components: {
      
    },
    props: {
      id: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        userData: [],
        error: null,
        deplacement: Object.assign({}, temp),
        editing: false,
        infos: null,
      }
    },
    async created() {
      if (this.isStarted == "true") {
        this.editing = true;
      }

      if (this.id) {
      await UserService.getDeplacement(this.$route.params.id).then((response) => {
        this.deplacement = response.data;
        let infos = this.deplacement.infos;
        if ('debut' in infos) {
          this.infos = infos['debut'];
        } else if ('fin' in infos) {
          this.infos = infos['fin'];
        }
        },
        error => {
            this.content = (error.response && error.response.data) || error.message || error.toString();
        }
        );
      }
      
      this.userData = JSON.parse(localStorage.getItem('userData'));
    },
    watch: {
      type(val) {
        if (val == 'add') {
          this.deplacement = Object.assign({}, temp);
        }
      }
    },
    mounted() {
      if (this.isStarted == "true") {
        this.editing = true;
      }
      
    },
    methods: {
      async reload() {
        this.$router.go();
      },
      async validationForm() {
              const deplacement = {
                updatedBy: "/api/users/" + this.userData.user.id,
                kmFin: parseInt(this.deplacement.kmFin),
                kmDebut: parseInt(this.deplacement.kmDebut),
              };
              UserService.patchDeplacement(this.$route.params.id, deplacement).then(
                () => {
                  UserService.getUserMe().then(
                    response => {
                      localStorage.setItem('userData', response.data);
                    },
                    error => {
                      this.content = (error.response && error.response.data) || error.message || error.toString();
                    }
                  );
                },
              );
              router.push('/home');
              this.reload();
      },  
    }
  }
  </script>
  
  