//import axios from 'axios';
import api from './api.js'
//import authHeader from './auth-header';

//const urlApi = "https://127.0.0.1:8000/api/";
const urlApi = "https://mybusiness.betterb.dev/api/";

class UserService {

getServerKey() {
    return api.get(urlApi + 'key');
}

getSubscription(endpoint) {
    return api.get(urlApi + 'subscriptions?endpoint=' + endpoint);
}

postSubscription(data)
{
    return api.post(urlApi + 'subscriptions', data);
}

getLastVersion() {
    return api.get(urlApi + 'lastPwa');
}

getUserMe(filters) {
    return api.get(urlApi + 'me/' + filters);
}

// Projets
getProjets(filters) {
    return api.get(urlApi + 'projets?q=' + filters);
}

getProjet(id) {
    return api.get(urlApi + 'projets/' + id);
}

// Actions
getActions(filters) {
    return api.get(urlApi + 'actions?q=' + filters);
}

getAction(id) {
    return api.get(urlApi + 'actions/' + id);
}

patchAction(id, data)
{
    return api.patch(urlApi + 'actions/' + id, data);
}

// Annuaire
getAnnuaireSearch(search)
{
    return api.get(urlApi + 'clients?annuaireSearch=' + search);
}


// Clients
getClients() {
    return api.get(urlApi + 'clients?q=crm');
}

getClientsSearch(search) {
    return api.get(urlApi + 'clients?search=' + search);
}

getClient(id) {
    return api.get(urlApi + 'clients/' + id);
}

// Prospects
getProspects() {
    return api.get(urlApi + 'organismes?q=crm');
}

getProspectSearch(search, filter) {
    return api.get(urlApi + 'organismes?searchProspect=' + search + '&' + filter);
}

// Organismes
getOrganismesByClient(clientId) {
    return api.get(urlApi + 'organismes?clientId=' + clientId);
}

getOrganisme(id) {
    return api.get(urlApi + 'organismes/' + id);
}

// Contacts
getContactsByClient(clientId) {
    return api.get(urlApi + 'contacts?clientId=' + clientId);
}

getContactsByOrganisme(organismeId) {
    return api.get(urlApi + 'contacts?organismeId=' + organismeId);
}

getContact(id) {
    return api.get(urlApi + 'contacts/' + id);
}

// Collaborateurs
getCollaborateur(id) {
    return api.get(urlApi + 'collaborateurs/' + id);
}

// SPANCO
getSpanco() {
    return api.get(urlApi + 'organismes?q=spanco');
}

// MEMO
getMemo(id) {
    return api.get(urlApi + 'memos/' + id);
}

modifyOrganismeByMemo(id, setSpancoValue, setSpancoNull) {
    return api.get(urlApi + 'memos?q=modifyOrganisme&id=' + id + '&spancoValue=' + setSpancoValue + '&spancoNull=' + setSpancoNull);
}

postMemo(data)
{
    return api.post(urlApi + 'memos', data);
}

getMemoPilotes() {
    return api.get(urlApi + 'users?q=memo');
}

// Vehicules
getVehicules() {
    return api.get(urlApi + 'vehicules/');
}

getVehiculesSearch(search) {
    return api.get(urlApi + 'vehicules?search=' + search);
}

getVehiculesForm() {
    return api.get(urlApi + 'vehicules?q=form');
}

getVehicule(id) {
    return api.get(urlApi + 'vehicules/' + id);
}

// Déplacements
getDeplacement(id) {
    return api.get(urlApi + 'deplacements/' + id);
}

getLastDeplacement() {
    return api.get(urlApi + 'deplacements?last');
}

patchDeplacement(id, data)
{
    return api.patch(urlApi + 'deplacements/' + id, data);
}

postDeplacement(data)
{
    return api.post(urlApi + 'deplacements', data);
}

}

export default new UserService();
