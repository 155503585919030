import UserService from "./user.service";

function main() {
    // récupère la permission de recevoir des notifications push
    const permission = Notification?.permission;
    
    // si l'utilisateur a accepté on déclenche l'abonnement
    if (permission === 'granted') {
        registerServiceWorker();
    }
}

// charge le service worker pour pouvoir faire une demande de souscription 
async function registerServiceWorker() {
  // récupère le service worker
  const registration = await navigator.serviceWorker.register("/service-worker.js");

  // récupère la souscription via l'api pushManager
  let subscription = await registration.pushManager.getSubscription();

  // L'utilisateur n'est pas déjà abonné, on l'abonne au notification push
  if (!subscription) {
    subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true, // reste toujours à true
      applicationServerKey: await getPublicKey(),
    });
  }

  // enregistre en BDD la souscription
  await saveSubscription(subscription);
  }
  
  // récupère la clé public du projet symfony définie dans le .env
  async function getPublicKey() {
    let key = null;

    await UserService.getServerKey().then((response) => {
            key = JSON.parse(response.data);
        },
        error => {
            this.content = (error.response && error.response.data) || error.message || error.toString();
        }
    );
    
    return urlBase64ToUint8Array(key);
  }

  // enregistre en BDD la souscription
  async function saveSubscription(subscription)
  {
    let userData = JSON.parse(localStorage.getItem('userData'));
    let subscribe = subscription.toJSON();
    
    const entity = {
      user: 'api/users/' + userData.user.id,
      endpoint: subscribe.endpoint,
      publicKey: subscribe.keys.p256dh,
      authToken: subscribe.keys.auth,
    };

    await UserService.getSubscription(subscribe.endpoint).then(
      response => {
        if (response.data['hydra:member'].length === 0) {
          UserService.postSubscription(entity).then(
            response => {
              console.log(response)
            },
            error => {
              this.content = (error.response && error.response.data) || error.message || error.toString();
            }
          );
        }
      },
      error => {
        this.content = (error.response && error.response.data) || error.message || error.toString();
      }
    );
  }

  // encore en base64URL la clé public du projet symfony définie dans le .env
  function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

 

export default main()